import axios, { AxiosInstance } from 'axios'
import { getUserInfo } from '@/lib/user'

export const getElasticSearchClient = (): AxiosInstance => {
  const elasticSearch = axios.create({
    baseURL: process.env.VUE_APP_ELASTIC_SEARCH_URL,
    timeout: 10000,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  })

  elasticSearch.interceptors.request.use(
    async (config) => {
      if (!config.headers) {
        config.headers = {}
      }
      const userInfo: any = await getUserInfo()
      const apiKey = userInfo.attributes['custom:ES_API_KEY']
      if (!apiKey) {
        throw new Error('No API key for ElasticSearch found')
      }
      config.headers.authorization = `ApiKey ${apiKey}`
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )
  return elasticSearch
}
