import Vue from 'vue'
import { DatePickerData } from '@/lib/reports/types'
import moment from 'moment-timezone'
import DatePicker from '../components/reports/DatePicker.vue'

export default Vue.extend({
  name: 'DatePickerMixin',
  components: {
    DatePicker,
  },
  data() {
    return {
      datePicker: {
        // startDate: moment({ year: 2021, month: 3, day: 1 })
        //   .tz('UTC')
        //   .startOf('month')
        //   .toDate(),
        // endDate: moment({ year: 2025, month: 7, day: 1 })
        //   .tz('UTC')
        //   .endOf('month')
        //   .toDate(),
        startDate: moment
          .tz('America/New_York')
          .subtract(6, 'days')
          .startOf('day')
          .toDate(),
        endDate: moment.tz('America/New_York').endOf('day').toDate(),
      } as DatePickerData,
    }
  },
})
