















































































import Vue from 'vue'
import * as vClickOutside from 'v-click-outside-x'
import moment from 'moment-timezone'

enum DateRangeEnum {
  Custom = 'custom',
  Today = 'today',
  Yesterday = 'yesterday',
  Last7Days = 'last7Days',
  Last30Days = 'last30Days',
  Last90Days = 'last90Days',
  LastMonth = 'lastMonth',
  LastYear = 'lastYear',
  WeekToDate = 'weekToDate',
  MonthToDate = 'monthToDate',
  QuarterToDate = 'quarterToDate',
  YearToDate = 'yearToDate',
  FourthQuarterThisYear = '4thQuarterThisYear',
  ThirdQuarterThisYear = '3rdQuarterThisYear',
  SecondQuarterThisYear = '2ndQuarterThisYear',
  FirstQuarterThisYear = '1stQuarterThisYear',
  FourthQuarterLastYear = '4thQuarterLastYear',
  ThirdQuarterLastYear = '3rdQuarterLastYear',
  SecondQuarterLastYear = '2ndQuarterLastYear',
  FirstQuarterLastYear = '1stQuarterLastYear',
}

interface Copy {
  dateRange: DateRangeEnum
  startDate: Date
  endDate: Date
}

interface Data {
  dateRange: DateRangeEnum
  previousCompareType: string
  openTimestamp: number | null
  isFiltersOpen: boolean

  // This is being held internally
  copy: Copy
}

export default Vue.extend({
  name: 'DatePicker',
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    startDate: {
      type: Date,
      default: moment.tz('America/New_York').startOf('day').toDate(),
    },
    endDate: {
      type: Date,
      default: moment
        .tz('America/New_York')
        .endOf('day')
        .subtract(1, 'minute')
        .toDate(),
    },
  },
  data(): Data {
    return {
      dateRange: DateRangeEnum.Last7Days as DateRangeEnum,
      previousCompareType: 'previousPeriod',
      openTimestamp: null,
      isFiltersOpen: false,
      copy: {
        dateRange: DateRangeEnum.Last7Days as DateRangeEnum,
        startDate: new Date(),
        endDate: new Date(),
      },
    }
  },
  computed: {
    dateRangeLabelMap(): { [key in DateRangeEnum]: string } {
      return {
        [DateRangeEnum.Custom]: 'Custom',
        [DateRangeEnum.Today]: 'Today',
        [DateRangeEnum.Yesterday]: 'Yesterday',
        [DateRangeEnum.Last7Days]: 'Last 7 days',
        [DateRangeEnum.Last30Days]: 'Last 30 days',
        [DateRangeEnum.Last90Days]: 'Last 90 days',
        [DateRangeEnum.LastMonth]: 'Last month',
        [DateRangeEnum.LastYear]: 'Last year',
        [DateRangeEnum.WeekToDate]: 'Week to date',
        [DateRangeEnum.MonthToDate]: 'Month to date',
        [DateRangeEnum.QuarterToDate]: 'Quarter to date',
        [DateRangeEnum.YearToDate]: 'Year to date',
        [DateRangeEnum.FourthQuarterThisYear]: `4th Quarter (${this.thisYear})`,
        [DateRangeEnum.ThirdQuarterThisYear]: `3rd Quarter (${this.thisYear})`,
        [DateRangeEnum.SecondQuarterThisYear]: `2nd Quarter (${this.thisYear})`,
        [DateRangeEnum.FirstQuarterThisYear]: `1st Quarter (${this.thisYear})`,
        [DateRangeEnum.FourthQuarterLastYear]: `4th Quarter (${this.lastYear})`,
        [DateRangeEnum.ThirdQuarterLastYear]: `3rd Quarter (${this.lastYear})`,
        [DateRangeEnum.SecondQuarterLastYear]: `2nd Quarter (${this.lastYear})`,
        [DateRangeEnum.FirstQuarterLastYear]: `1st Quarter (${this.lastYear})`,
      }
    },
    label(): string {
      return this.dateRangeLabelMap[this.dateRange]
    },
    hasFourthQuarterThisYear(): boolean {
      return !moment.tz('America/New_York').quarter(4).isAfter()
    },
    hasThirdQuarterThisYear(): boolean {
      return !moment.tz('America/New_York').quarter(3).isAfter()
    },
    hasSecondQuarterThisYear(): boolean {
      return !moment.tz('America/New_York').quarter(2).isAfter()
    },
    thisYear(): number {
      return new Date().getFullYear()
    },
    lastYear(): number {
      return this.thisYear - 1
    },
    isCustomDateRange(): boolean {
      return this.copy.dateRange === DateRangeEnum.Custom
    },
  },
  created() {
    this.copy = {
      dateRange: this.dateRange,
      startDate: new Date(this.startDate),
      endDate: new Date(this.endDate),
    }
  },
  // mounted() {
  //   this.$nextTick(() => {
  //     setTimeout(() => {
  //       this.onDateRangeChange(DateRangeEnum.LastYear)
  //     }, 1000)
  //   })
  // },
  methods: {
    onStartDateInput(newValue: Date): void {
      this.copy.dateRange = DateRangeEnum.Custom
      const dayKey = `${newValue.getFullYear()}-${(newValue.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${newValue.getDate().toString().padStart(2, '0')}`
      this.copy.startDate = moment
        .tz(dayKey, 'America/New_York')
        .startOf('day')
        .toDate()
    },
    onEndDateInput(newValue: Date): void {
      const dayKey = `${newValue.getFullYear()}-${(newValue.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${newValue.getDate().toString().padStart(2, '0')}`
      this.copy.endDate = moment
        .tz(dayKey, 'America/New_York')
        .endOf('day')
        .toDate()
    },
    onDateRangeChange(dateRange: DateRangeEnum): void {
      const { startDate, endDate } = this.getDateRangesFromSelect(dateRange)
      this.copy.dateRange = dateRange
      this.copy.startDate = startDate
      this.copy.endDate = endDate
    },
    open(): void {
      this.openTimestamp = Date.now()
      this.isFiltersOpen = true
    },
    close(): void {
      const now = Date.now()
      if (this.openTimestamp && now - this.openTimestamp > 10) {
        this.isFiltersOpen = false
      }
    },
    toggle(): void {
      if (this.isFiltersOpen) {
        this.close()
      } else {
        this.open()
      }
    },
    getDateRangesFromSelect(
      option: string
    ): { startDate: Date; endDate: Date } {
      switch (option) {
        case DateRangeEnum.Today: {
          return {
            startDate: moment.tz('America/New_York').startOf('day').toDate(),
            endDate: moment
              .tz('America/New_York')
              .endOf('day')
              .subtract(1, 'second')
              .toDate(),
          }
        }
        case DateRangeEnum.Yesterday: {
          return {
            startDate: moment
              .tz('America/New_York')
              .subtract(1, 'day')
              .startOf('day')
              .toDate(),
            endDate: moment
              .tz('America/New_York')
              .endOf('day')
              .subtract(1, 'day')
              .toDate(),
          }
        }
        case DateRangeEnum.Last7Days: {
          return {
            startDate: moment
              .tz('America/New_York')
              .subtract(6, 'day')
              .startOf('day')
              .toDate(),
            endDate: moment.tz('America/New_York').endOf('day').toDate(),
          }
        }
        case DateRangeEnum.Last30Days: {
          return {
            startDate: moment
              .tz('America/New_York')
              .subtract(29, 'day')
              .startOf('day')
              .toDate(),
            endDate: moment.tz('America/New_York').endOf('day').toDate(),
          }
        }
        case DateRangeEnum.Last90Days: {
          return {
            startDate: moment
              .tz('America/New_York')
              .subtract(89, 'day')
              .startOf('day')
              .toDate(),
            endDate: moment.tz('America/New_York').endOf('day').toDate(),
          }
        }
        case DateRangeEnum.LastMonth: {
          return {
            startDate: moment
              .tz('America/New_York')
              .startOf('month')
              .subtract(1, 'month')
              .toDate(),
            endDate: moment
              .tz('America/New_York')
              .subtract(1, 'month')
              .endOf('month')
              .toDate(),
          }
        }
        case DateRangeEnum.LastYear: {
          return {
            startDate: moment
              .tz('America/New_York')
              .subtract(1, 'year')
              .startOf('year')
              .toDate(),
            endDate: moment
              .tz('America/New_York')
              .subtract(1, 'year')
              .endOf('year')
              .toDate(),
          }
        }
        case DateRangeEnum.WeekToDate: {
          return {
            startDate: moment.tz('America/New_York').startOf('week').toDate(),
            endDate: moment.tz('America/New_York').endOf('week').toDate(),
          }
        }
        case DateRangeEnum.MonthToDate: {
          return {
            startDate: moment.tz('America/New_York').startOf('month').toDate(),
            endDate: moment.tz('America/New_York').endOf('month').toDate(),
          }
        }
        case DateRangeEnum.QuarterToDate: {
          return {
            startDate: moment
              .tz('America/New_York')
              .startOf('quarter')
              .toDate(),
            endDate: moment.tz('America/New_York').endOf('quarter').toDate(),
          }
        }
        case DateRangeEnum.YearToDate: {
          return {
            startDate: moment.tz('America/New_York').startOf('year').toDate(),
            endDate: moment.tz('America/New_York').endOf('year').toDate(),
          }
        }
        case DateRangeEnum.FourthQuarterThisYear: {
          return {
            startDate: moment
              .tz('America/New_York')
              .quarter(4)
              .startOf('quarter')
              .toDate(),
            endDate: moment
              .tz('America/New_York')
              .quarter(4)
              .endOf('quarter')
              .toDate(),
          }
        }
        case DateRangeEnum.ThirdQuarterThisYear: {
          return {
            startDate: moment
              .tz('America/New_York')
              .quarter(3)
              .startOf('quarter')
              .toDate(),
            endDate: moment
              .tz('America/New_York')
              .quarter(3)
              .endOf('quarter')
              .toDate(),
          }
        }
        case DateRangeEnum.SecondQuarterThisYear: {
          return {
            startDate: moment
              .tz('America/New_York')
              .quarter(2)
              .startOf('quarter')
              .toDate(),
            endDate: moment
              .tz('America/New_York')
              .quarter(2)
              .endOf('quarter')
              .toDate(),
          }
        }
        case DateRangeEnum.FirstQuarterThisYear: {
          return {
            startDate: moment
              .tz('America/New_York')
              .quarter(1)
              .startOf('quarter')
              .toDate(),
            endDate: moment
              .tz('America/New_York')
              .quarter(1)
              .endOf('quarter')
              .toDate(),
          }
        }
        case DateRangeEnum.FourthQuarterLastYear: {
          return {
            startDate: moment
              .tz('America/New_York')
              .subtract(1, 'year')
              .quarter(4)
              .startOf('quarter')
              .toDate(),
            endDate: moment
              .tz('America/New_York')
              .subtract(1, 'year')
              .quarter(4)
              .endOf('quarter')
              .toDate(),
          }
        }
        case DateRangeEnum.ThirdQuarterLastYear: {
          return {
            startDate: moment
              .tz('America/New_York')
              .subtract(1, 'year')
              .quarter(3)
              .startOf('quarter')
              .toDate(),
            endDate: moment
              .tz('America/New_York')
              .subtract(1, 'year')
              .quarter(3)
              .endOf('quarter')
              .toDate(),
          }
        }
        case DateRangeEnum.SecondQuarterLastYear: {
          return {
            startDate: moment
              .tz('America/New_York')
              .subtract(1, 'year')
              .quarter(2)
              .startOf('quarter')
              .toDate(),
            endDate: moment
              .tz('America/New_York')
              .subtract(1, 'year')
              .quarter(2)
              .endOf('quarter')
              .toDate(),
          }
        }
        case DateRangeEnum.FirstQuarterLastYear: {
          return {
            startDate: moment
              .tz('America/New_York')
              .subtract(1, 'year')
              .quarter(1)
              .startOf('quarter')
              .toDate(),
            endDate: moment
              .tz('America/New_York')
              .subtract(1, 'year')
              .quarter(1)
              .endOf('quarter')
              .toDate(),
          }
        }
        default: {
          throw new Error(`cannot find date range for option "${option}"`)
        }
      }
    },
    cancel(): void {
      this.copy.startDate = new Date(this.startDate)
      this.copy.endDate = new Date(this.endDate)
      this.copy.dateRange = this.dateRange
      this.close()
    },
    save(): void {
      this.dateRange = this.copy.dateRange
      this.$emit('range-changed', {
        startDate: this.copy.startDate,
        endDate: this.copy.endDate,
      })
      this.close()
    },
    formatDate(date: Date) {
      return new Intl.DateTimeFormat('en-US', {
        timeZone: 'America/New_York',
      }).format(date)
    },
  },
})
